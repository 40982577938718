<template>
    <div id="datenschutzWraper">
        <div class="container">
            <h1>Datenschutz</h1>
            <p>
                Der Schutz Ihrer Daten ist wichtig. In unserer folgenden Datenschutzerklärung, die wir nach den Vorgaben der europäischen Datenschutzgrundverordnung (DSVGO, insbesondere Art 13 DSGVO) mit den dort vorgesehenen Informationen erstellt haben, finden Sie Informationen zu den datenschutzrechtlichen Aspekten unseres Internetauftritts.
            </p>
            <p><b>1. Verantwortliche</b></p>
            <p>
                Verantwortliche im Sinne der Datenschutzgrundverordnung sind die Digital Mail Solution GmbH, Gormannstrasse 14, 10119 Berlin, gesetzlich vertreten durch den Geschäftsführer Herrn Rouven Stroß, ebendort, als Betreiberin dieses Internetauftritts und Herr Rouven Stroß als Geschäftsführer der Seitenbetreiberin. Sie erreichen uns postalisch unter der angegebenen Anschrift oder per E-Mail unter datenschutz@digital-mail-solution.com.
            </p>
            <p><b>2. Zwecke der Datenverarbeitung</b></p>
            <p>
                Sofern wir personenbezogene Daten verarbeiten, dient diese Verarbeitung der Durchführung unseres Angebotes (z. B. Versendung eines Bestätigungs- oder Downloadlinks, Durchführung eines Gewinnspiels), dem Online-Marketing (z. B. Versendung von Newslettern), statistischen Zwecken sowie der Verbesserung unseres Angebotes.
                <br><br>
                Rechtsgrundlage für die Verarbeitung kann eine von Ihnen erbetene und erteilte Einwilligung (Art 6 Abs. 1 lit a) DSGVO) oder die Notwendigkeit der Verarbeitung für die Durchführung eines Vertrags mit Ihnen (Art 6 Abs. 1 lit. b9 DSGVO) oder eine uns treffende rechtliche Verpflichtung (Art 6 Abs. 1 lit c) DSGVO) oder die Wahrung berechtigter Interessen (Art 6 Abs. 1 kit f) DSGVO), z. B. die Dokumentation der Inanspruchnahme unseres Angebotes oder ein Zusammenkommen mehrerer der vorstehenden Gründe sein.
            </p>
            <p><b>3. Daten, die wir verarbeiten</b></p>
            <p>
                a. Wir verarbeiten personenbezogene Daten<br>
                <br>
                – wenn Sie unsere Internetseite besuchen<br>
                und / oder<br>
                – einen dort von uns angebotenen Dienst nutzen<br>
                und / oder<br>
                – uns kontaktieren<br>
                und / oder<br>
                – Ihre Daten in auf unserer Webseite vorgehaltene Eingabefelder eintragen<br>
                und / oder<br>
                – mit uns einen Vertrag schließen, bei uns eine Bestellung tätigen oder sonst uns gegenüber eine Willenserklärung abgeben<br>
                und / oder<br>
                – wenn Sie E-Mails von uns erhalten.<br>
                <br>
                b. Wenn Sie unsere Internetseite besuchen, verarbeiten wir folgende Daten als Server Logfiles :<br>
                <br>
                – IP Adresse (anonymisiert)<br>
                – Datum und Uhrzeit des Besuchs<br>
                – verwendeter Internetbrowser<br>
                – verwendetes Betriebssystem<br>
                – Dauer Ihres Besuchs, von Ihnen angesehene Teile unserer Seite, getätigte Downloads, Menge der gesendeten Daten<br>
                – die Internetseite, von der aus Sie auf unsere Seite gelangt sind.<br>
                <br>
                c. Wenn Sie einen von uns angebotenen Dienst nutzen, verarbeiten wir zusätzlich zu den vorgenannten Informationen<br>
                <br>
                – alle von Ihnen bei der Inanspruchnahme angegebenen Daten<br>
                – die Informationen zu Ihrer Inanspruchnahme des Dienstes<br>
                <br>
                d. Wenn Sie uns kontaktieren, verarbeiten wir zusätzlich zu den vorgenannten Informationen<br>
                <br>
                – alle Angaben, die Sie uns bei Ihrer Kontaktaufnahme zur Verfügung stellen, z. B. Namen, Vorname, Adresse, Ihr Anliegen<br>
                <br>
                e. Wenn Sie Ihre Daten in auf unserer Webseite vorgehaltene Eingabefelder eintragen, verarbeiten wir zusätzlich zu den vorgenannten Informationen<br>
                <br>
                – alle Daten, die Sie dort eingeben, z. B. Name, Vorname, Kontaktdaten<br>
                <br>
                f. Wenn Sie mit uns einen Vertrag schließen, bei uns eine Bestellung tätigen oder sonst uns gegenüber eine Willenserklärung abgeben, verarbeiten wir zusätzlich zu den vorgenannten Informationen<br>
                <br>
                – die zur Vertragsdurchführung erforderlichen Informationen, z. B. Zahlungsmodalitäten.<br>
                <br>
                g. Wenn Sie E-Mails von uns erhalten, verarbeiten wir zusätzlich zu den vorgenannten Daten<br>
                <br>
                – die uns von Ihrem benutzen Endgerät zur Verfügung gestellten Informationen, welche E-Mails Sie öffnen und wie Sie mit dieser E-Mail weiter verfahren, damit wir nachvollziehen können, ob unsere E-Mails für Sie interessant sind und wir unseren Service für Sie evtl. interessanter gestalten können (Art. 6 Abs. 1 lit. f DSGVO).<br>
                <br>
                h. Sonderinformationen<br>
                <br>
                Wir informieren Sie jeweils, wenn die Bereitstellung der personenbezogenen Daten durch Sie gesetzlich oder vertraglich vorgeschrieben oder für einen Vertragsabschluss erforderlich ist, Sie verpflichtet sind, die entsprechenden Daten bereit zu stellen und welche Folge eine Nichtbereitstellung hat.<br>
            </p>
            <p><b>4. SSL-Verschlüsselung, Cookies, soziale Netzwerke, Analyse-Tools</b></p>
            <p>
                a. SSL-Verschlüsselung<br>
                <br>
                Wir nutzen bei der Übermittlung vertraulicher Daten eine SSL-Verschlüsselung. Sie erkennen dies an der Anzeige „https://“ in Ihrem Browser.<br>
                <br>
                b. Cookies<br>
                <br>
                Auf unserer Internetseite verwenden wir Cookies, kleine Dateien, die auf Ihrer Festplatte gespeichert werden und in denen Informationen gespeichert sind, die der Server, der den Cookie gesetzt hat, wieder auslesen kann. Dies ist nur möglich, wenn Sie dies in Ihrem Browser erlaubt haben, wobei die Erlaubnis die Standardeinstellung der meisten gängigen Browser ist. Diese Standardeinstellung können Sie in Ihrem Browser individuell ändern und damit den Einsatz unserer Cookies unterbinden.<br>
                <br>
                c. Soziale Netzwerke allgemein<br>
                <br>
                Sofern wir auf unserer Internetseite Buttons mit den Namen eines Sozialen Netzwerks bereitstellen, können Sie, wenn Sie den entsprechenden Button anklicken, das entsprechende Soziale Netzwerk bzw. dessen Internetseite öffnen. Sie gelangen dann von unserer Seite aus auf die Seite des jeweiligen sozialen Netzwerks. Dabei kann vom Betreiber des sozialen Netzwerks gespeichert werden, dass Sie von unserer Seite aus die des jeweiligen sozialen Netzwerks aufgerufen haben. Bitte informieren Sie sich hierzu und über die Verarbeitung Ihrer Daten im jeweiligen sozialen Netzwerk in den Datenschutzerklärungen der von Ihnen genutzten sozialen Netzwerke.<br>
                <br>
                d. facebook<br>
                <br>
                Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo Park, California 94025, USA, integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem “Like-Button” (“Gefällt mir”) auf unserer Seite. Eine übersicht über die Facebook-Plugins finden Sie hier: http://developers.facebook.com/docs/plugins/.<br>
                Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook “Like-Button” anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter http://de-de.facebook.com/policy.php.<br>
                Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook- Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.<br>
                <br>
                e. Twitter<br>
                <br>
                Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden angeboten durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von Twitter und der Funktion “Re-Tweet” werden die von Ihnen besuchten Webseiten mit Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Twitter unter http://twitter.com/privacy.<br>
                Ihre Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen unter http://twitter.com/account/settings ändern.<br>
                <br>
                f. Google +1<br>
                <br>
                Unsere Seiten nutzen Funktionen von Google +1. Anbieter ist die Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.<br>
                Erfassung und Weitergabe von Informationen: Mithilfe der Google +1-Schaltfläche können Sie Informationen weltweit veröffentlichen. Über die Google +1-Schaltfläche erhalten Sie und andere Nutzer personalisierte Inhalte von Google und unseren Partnern. Google speichert sowohl die Information, dass Sie für einen Inhalt +1 gegeben haben, als auch Informationen über die Seite, die Sie beim Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen auf Websites und Anzeigen im Internet eingeblendet werden. Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die Google-Dienste für Sie und andere zu verbessern. Um die Google +1-Schaltfläche verwenden zu können, benötigen Sie ein weltweit sichtbares, öffentliches Google-Profil, das zumindest den für das Profil gewählten Namen enthalten muss. Dieser Name wird in allen Google-Diensten verwendet. In manchen Fällen kann dieser Name auch einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten über Ihr Google-Konto verwendet haben. Die Identität Ihres Google- Profils kann Nutzern angezeigt werden, die Ihre E-Mail-Adresse kennen oder über andere identifizierende Informationen von Ihnen verfügen.<br>
                Verwendung der erfassten Informationen: Neben den oben erläuterten Verwendungszwecken werden die von Ihnen bereitgestellten Informationen gemäß den geltenden Google-Datenschutzbestimmungen genutzt. Google veröffentlicht möglicherweise zusammengefasste Statistiken über die +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner weiter, wie etwa Publisher, Inserenten oder verbundene Websites.<br>
                <br>
                g. Google AdSense<br>
                <br>
                Unsere Website benutzt Google AdSense, einen Dienst zum Einbinden von Werbeanzeigen der Google Inc. (“Google”). Google AdSense verwendet sog. “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website ermöglicht. Google AdSense verwendet auch so genannte Web Beacons (unsichtbare Grafiken).<br>
                Durch diese Web Beacons können Informationen wie der Besucherverkehr auf diesen Seiten ausgewertet werden. Die durch Cookies und Web Beacons erzeugten Informationen über die Benutzung dieser Website (einschließlich Ihrer IP-Adresse) und Auslieferung von Werbeformaten werden an einen Server von Google in den USA übertragen und dort gespeichert. Diese Informationen können von Google an Vertragspartner von Google weiter gegeben werden. Google wird Ihre IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten Daten zusammenführen.<br>
                Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.<br>
                <br>
                h. Google Analytics<br>
                <br>
                Diese Website nutzt den Dienst „Google Analytics“, welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten wird, zur Analyse der Websitebenutzung durch Nutzer. Der Dienst verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen werden im Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.<br>
                <br>
                Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum gekürzt. Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche die Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten Informationen eine Auswertung der Websitenutzung und der Websiteaktivität und erbringt mit der Internetnutzung verbundene Dienstleistungen.<br>
                <br>
                Sie haben die Möglichkeit, die Speicherung des Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen können, wenn Ihr Browser keine Cookies zulässt.<br>
                <br>
                Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google Inc. genutzt werden. Folgender Link führt Sie zu dem entsprechenden Plugin:https://tools.google.com/dlpage/gaoptout?hl=de<br>
                Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.: https://support.google.com/analytics/answer/6004245?hl=de<br>
                <br>
                Widerspruch<br>
                <br>
                Wenn Sie mit der Speicherung und Auswertung der erzeugten Nutzungsinformationen nicht einverstanden sind, können Sie dieser nachfolgend widersprechen. In diesem Fall wird in Ihrem Browser ein sog. Deaktivierungs-Cookie abgelegt, was zur Folge hat, dass Piwik keinerlei Sitzungsdaten erhebt. Achtung: Wenn Sie ihre Cookies löschen, so hat dies zur Folge, dass auch das Deaktivierungs-Cookie gelöscht wird und von Ihnen erneut abgelegt werden muss. Auf der folgenden Internetseite haben Sie die Möglichkeit, die Datenerfassung durch Piwik zu deaktivieren.<br>
            </p>
            <p><b>5. Weitergabe Ihrer Daten an Dritte</b></p>
            <p>
                Sofern Sie über unsere Seite Verträge mit Dritten schließen, geben wir die zur Vertragsdurchführung erforderlichen Daten an diese Dritten weiter, damit diese den Vertrag erfüllen können (Art. 6 Abs. 1 lit. b und f DSGVO). Hierüber und über die im Einzelnen weiter gegebenen Daten werden Sie an der jeweiligen Stelle vorher informiert.<br>
                <br>
                Ihre E-Mailadresse geben wir – nur, sofern Sie und in dem Umfang, in dem Sie vorher zugestimmt haben – an die im Zeitpunkt Ihrer Einwilligung auf unserer Seite namentlich genannten Sponsoren weiter, damit diese Ihnen an die E-Mail-Adresse Newsletter zu den von Ihnen gewählten und auf unserer Internetseite genannte Produktkategorien zukommen lassen können.<br>
                <br>
                Sofern Sie jeweils gesondert in eine Weitergabe Ihrer uns mitgeteilten Telefonnummer oder Postadresse eingewilligt haben, geben wir diese Daten zum Zwecke des Marketings per Post bzw. Telefon an die zum Zeitpunkt Ihrer Einwilligung auf unserer Seite namentlich genannten Sponsoren weiter, damit diese Ihnen über diese Kanäle auf Sie zugeschnittene Produktwerbung zu den von Ihnen gewähnten, auf unserer Internetseite angegebenen Produkten zukommen lassen können.<br>
                <br>
                Diese Weitergabe erfolgt im gesetzlichen Rahmen der Art. 6 Abs. 1 lit. b und f DSGVO.<br>
                <br>
                Wenn eine gesetzliche Verpflichtung zur Weitergabe Ihrer personenbezogenen Daten an Dritte besteht (Art. 6 Abs. 1 lit. c) DSGVO) besteht, kommen wir dieser Verpflichtung nach. Gleiches gilt im Falle einer rechtlichen Auseinandersetzung (Art. 6 Abs. 1 lit. f DSGVO), im Falle einer Übernahme unseres Unternehmens durch einen Dritten oder einer Insolvenz (Art. 6 Abs. 1 lit. f DSGVO).<br>
                <br>
                Wir selbst haben nicht die Absicht, Ihre Daten an ein Drittland oder eine internationale Organisation zu übermitteln (Art. 13 Abs. 1 lit. f) DSGVO). Allerdings bedingt die Nutzung von Google Analytics und Google Fonts eine Übermittlung personenbezogener Daten an Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Die Google LLC ist Mitglied im EU-US Privacy Shield (www.privacyshield.gov). Ein Angemessenheitsbeschluss der Europäischen Kommission fehlt.<br>
            </p>
            <p><b>6. Wie lange speichern wir Ihre Daten?</b></p>
            <p>

                Wir speichern Ihre Daten<br>
                <br>
                – so lange Sie uns das gestatten<br>
                – so lange wir gesetzlich dazu verpflichtet sind<br>
                – so lange ein berechtigtes Interesse dazu besteht<br>
                – so lange die Grundsätze der Datenverarbeitung aus Art 5 ff DSGVO nicht entgegenstehen<br>
            </p>
            <p><b>7. Wo speichern wir Ihre Daten?</b></p>
            <p>
                Wir speichern Ihre Daten ausschließlich auf eigenen und von uns angemieteten Servern innerhalb der Europäischen Union.
            </p>
            <p><b>8. Wie schützen wir Ihre Daten?</b></p>
            <p>
                Wir schützen Ihre Daten durch alle sinnvollen und tatsächlich sowie uns finanziell möglichen Vorkehrungen administrativer, organisatorischer, technischer und physischer Art vor unberechtigtem Zugriff. Unsere Server sind nach dem aktuellen Stand der Technik vor unberechtigtem Zugriff geschützt, unsere Mitarbeiter geschult und zuverlässig, die gespeicherten Daten verschlüsselt und unsere Räume physisch gegen unberechtigten Zugang geschützt.
            </p>
            <p><b>9. Ihre Rechte</b></p>
            <p>
                a. Auskunft<br>
                <br>
                Sie haben das Recht, jederzeit Auskunft zu der Verarbeitung Ihrer personenbezogenen Daten bei uns anzufordern.<br>
                <br>
                b. Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch<br>
                <br>
                Sie haben das Recht, jederzeit mit Wirkung für die Zukunft der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen, deren Löschung oder Berichtigung oder eine Einschränkung der Verarbeitung bei uns zu fordern.<br>
                <br>
                c. Datenübertragbarkeit<br>
                <br>
                Sie haben das Recht auf Datenübertragbarkeit.<br>
                <br>
                d. Widerruf einer Einwilligung<br>
                <br>
                Sie können eine uns erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.<br>
                <br>
                e. Beschwerde<br>
                <br>
                Sie haben jederzeit das Recht, sich bei einer Aufsichtsbehörde zu beschweren.<br>
            </p>
            <p><b>10. Minderjährige</b></p>
            <p>
                Unsere Seite richtet sich ausschließlich an volljährige, voll geschäftsfähige Personen. Wir verarbeiten zumindest wissentlich keine personenbezogenen Daten von minderjährigen oder nicht geschäftsfähigen Personen.
            </p>
            <p><b>11. Automatisierte Entscheidungsfindung/Profiling</b></p>
            <p>
                Eine automatisierte Entscheidungsfindung, insbesondere ein Profiling im Sinne der Art 13 Abs. 2 lit f) und Art 22 Abs. 1 und erfolgt bei uns nicht.
            </p>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Datenschutz"
    }
</script>

<style scoped>
    #datenschutzWraper{padding:25px 0;}

</style>